
import { defineComponent, toRefs, ref,computed } from "vue";
import { useRouter } from "vue-router";
import { Modal } from "ant-design-vue";
import moment from "moment";
import TabBar from "./tabBar.vue";
import useEntitySiPayout from "@/hooks/payment/EntitySiPayout/useEntitySiPayout";
import TableWrapper from "../components/TableWrapper.vue";
import YearPicker from "@/views/Payment/components/YearPicker.vue";
import setModal from "./setModal.vue";
import viewModal from "./viewModal.vue";
import UploadDetail from "./uploadDetail.vue";
import DownloadModal from "./downLoadModal.vue";
import DemoCDSetModal from "./Demo/demoCDSetModal.vue";
import DemoCDPreviewModal from "./Demo/demoCDPreviewModal.vue";
import { getDealerBreakdownOverview } from "@/API/payment";
import { useStore } from "vuex";
import GetColumnWidth from "@/utils/payment/getColumnWidth";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const router = useRouter();
    const { commit } = useStore();
    const {
      column,
      query,
      pagination,
      data,
      getTableData,
      entityList,
      roundList,
      getRound,
      statusList,
      getSatusList,
      pageChange,
      sizeChange,
    } = useEntitySiPayout();
    const search = () => {
      pagination.pageNumber = 1
      getTableData();
    };
    const reset = () => {
      query.payoutYear = moment().format("YYYY");
      query.payoutRound = undefined;
      query.status = undefined;
      getRound();
      pagination.pageNumber = 1
      getTableData();
    };
    const handleTabChange = () => {
      getRound();
      getTableData();
    };
    const init = () => {
      getSatusList();
      query.entity = entityList.value?.[0];
      getRound();
      getTableData();
    };
    init();
    const filteredColumn = computed(() => {
      if (query.entity == "FBAC") {
        return column.value.filter((item) => !item?.filter);
      } else {
        return column.value;
      }
    });
    const approvedStatusList = ref([
      "Ready to release",
      "Releasing",
      "Released",
    ]);
    const notApprovedStatusList = ref(["Created", "Approving"]);
    const curPayoutRound = ref();
    const setModalVisible = ref(false);
    const handleSetModalShow = (record: any) => {
      if (
        !(
          approvedStatusList.value.includes(record.status) &&
          record.identification
        )
      ) {
        return false;
      }
      setModalVisible.value = true;
      curPayoutRound.value = record.payoutRound;
    };
    const viewModal = ref();
    const handleViewModalShow = (record: any) => {
      // if (record.identification || notApprovedStatusList.value.includes(record.status)) {
      //   return false;
      // }
      viewModal.value.getTableData(record.payoutRound, query.entity);
    };
    const handleNext = (record: any) => {
      if (
        !record.identification ||
        notApprovedStatusList.value.includes(record.status)
      ) {
        return false;
      }
      Modal.confirm({
        title:
          "Are you sure you have finished payment release set, and want to next step? ",
        okText: "Confirm",
        okType: "primary",
        cancelText: "Cancel",
        onOk() {
          commit("payment/updateBreakdownParams", {
            payoutRound: record.payoutRound,
            entity: query.entity,
          });
          router.push({
            path: "/paymentSales/DealerBreakdownReport",
            query: {
              type: "next",
            },
          });
          getDealerBreakdownOverview(query.entity, record.payoutRound);
        },
      });
    };
    const handleView = (record: any) => {
      // if (record.identification) {
      //   return false;
      // }
      commit("payment/updateBreakdownParams", {
        payoutRound: record.payoutRound,
        entity: query.entity,
      });
      router.push({
        path: "/paymentSales/DealerBreakdownReport",
        query: {
          type: "view",
        },
      });
    };
    const uploadModalVisible = ref(false);
    const handleUploadShow = () => {
      setModalVisible.value = false;
      uploadModalVisible.value = true;
    };
    const downloadModal = ref();
    const demoCDSetModal = ref();
    const demoCDPreviewModal = ref();
    const handleDownloadShow = (record: any) => {
      if (!approvedStatusList.value.includes(record.status)) {
        return false;
      }
      downloadModal.value.checkResult = undefined;
      downloadModal.value.getTableData(record.payoutRound, query.entity);
    };
    const handleDemoCDSetShow = (record: any, entity: any) => {
      if (!record.demoCarSetButtonHighlight) {
        return
      }
      demoCDSetModal.value.getReleaseRound(record.payoutRound, entity);
    };
    const handleDemoCDPreviewShow = (record: any, entity: any) => {
      if (!record.demoCarPreviewButtonHighlight) {
        return
      }
      demoCDPreviewModal.value.getPreviewData(record.payoutRound, entity);
    };
    const handleDemoCDViewShow = (record: any, entity: any) => {
      if (!record.demoCarViewButtonHighlight) {
        return
      }
      router.push({
        path: "/demoCDview",
        query: {
          releaseRound: record.payoutRound,
          entity,
        },
      });
    };
    const tableWidth = computed(() => GetColumnWidth(column.value));
    return {
      data,
      column,
      filteredColumn,
      ...toRefs(query),
      ...toRefs(pagination),
      entityList,
      roundList,
      statusList,
      isPagination: true,
      handleTabChange,
      search,
      reset,
      pageChange,
      sizeChange,
      setModalVisible,
      handleSetModalShow,
      viewModal,
      handleViewModalShow,
      handleNext,
      handleView,
      handleUploadShow,
      uploadModalVisible,
      handleDownloadShow,
      handleDemoCDSetShow,
      handleDemoCDPreviewShow,
      handleDemoCDViewShow,
      downloadModal,
      demoCDSetModal,
      demoCDPreviewModal,
      approvedStatusList,
      notApprovedStatusList,
      curPayoutRound,
      tableWidth,
    };
  },
  components: {
    TabBar,
    YearPicker,
    TableWrapper,
    setModal,
    viewModal,
    UploadDetail,
    DownloadModal,
    DemoCDSetModal,
    DemoCDPreviewModal,
  },
});
